<template>
    <Transition name="fade-transition">
        <div id="defaultapps">

            <v-card class="admin-card" max-height="80vh">

                <!--Default apps Header-->
                <!--<v-card-title class="secondary white--text text-h6">
                    Modify Default App Roles
                </v-card-title>-->
                <!-- body-->
                <div class="pa-1">
                    <v-container class=" default-body">

                        <approle v-for="(app, index) in apps" :key="index" :app="app">

                        </approle>

                    </v-container>

                    <div class="mt-2 pa-2">
                        <v-divider></v-divider>
                        <v-btn color="primary" block outlined class="pa-3" @click="saveDefault">Save</v-btn>
                    </div>
                </div>


                <v-overlay absolute :value="loading_init">
                    <v-progress-circular indeterminate :size="80" :width="7"
                                         color="primary"></v-progress-circular>
                </v-overlay>
            </v-card>

        </div>
    </Transition>
</template>


<style lang="scss" scoped>
    .default-body {
        max-height: 60vh;
        height: 60vh;
        overflow-y: scroll;
    }
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import approle from "./app-role-default";
    export default {
        name: "defaultapps",
        mixins: [bus_common],
        components: { approle },
        data() {
            return {
                apps: [],
                loading_init: false,
            };
        },
        methods: {
            async checkAdminRole() {
                //Double check the user has the correct roles
                let callback = () => { window.location.replace(this.loginURL); }

                if (this.$store.state.user.roles.indexOf('rl-admin') == -1) {

                    this.$swal({
                        title: "Invalid Role!",
                        text: "You do not have the needed role for this app, you will be redirected to the login page.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#283593',
                        timer: 4000,
                        didClose: callback
                    });
                }
            },
            async init() {

                this.loading_init = true;
                await this.checkAdminRole();

                var form = {
                    token: this.$store.state.user.token
                };

                await this.$axios.post('api/roles/defaultInit', form)
                    .then(response => {
                        this.apps = response.data.apps;

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },
            async saveDefault() {

                this.loading_init = true;

                var form = {
                    token: this.$store.state.user.token,
                    apps: this.apps,
                    email: this.$store.state.user.email,
                };

                await this.$axios.post('api/roles/savedefault', form)
                    .then(response => {
                        this.swall_it("Saved!", "The default roles were successfully saved.", "success", null, null);
                        this.apps = [];

                        this.$nextTick(() => {
                            this.apps = response.data.apps;

                        });

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },


        },
        computed: {




        },
        watch: {
            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "" && this.loading_init == false) {

                    this.init();
                }
            },

        },
        mounted() {

            if (this.$store.state.user.token != "" && this.loading_init == false) {

                this.init();
            }
        },
    };
</script>
