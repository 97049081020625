<template>
    <Transition name="fade-transition">
        <div id="editroles">

            <v-card class="pa-5 ">

                <v-row dense>
                    <v-col cols="12" lg="6">
                        <v-select :items="apps" item-text="appname" item-value="applicationId" v-model="app_select" outlined label="App"></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select :items="roles" item-text="roledisplay" item-value="rlsID" v-model="role_select" outlined label="Role"></v-select>
                    </v-col>
                </v-row>

                <v-data-iterator :items="results_search" :search="search_emails" :no-results-text="no_results_text" :loading-text="loading_text" :no-data-text="no_results_text"
                                 hide-default-footer disable-pagination>

                    <template v-slot:header>
                        <v-toolbar color="secondary lighten-1" dark dense elevation="4" class="mb-2">
                            <v-toolbar-title>Users ({{results_search.length}})</v-toolbar-title>
                        </v-toolbar>
                    </template>

                    <template v-slot:no-data>

                        <h4 class="ma-3">No users found</h4>

                    </template>

                    <template v-slot:no-results>

                        <h4 class="ma-3">No users found</h4>

                    </template>

                    <template v-slot:default="props">

                        <!--<v-form ref="results_form" v-model="results_form_valid" class="users_container">
                <v-row no-gutters dense v-for="(item,index) in props.items" :key="index">-->
                        <!--<v-btn small icon color="red darken-1" class="mr-1 mt-1">
                    <v-icon>
                        $delete
                    </v-icon>
                </v-btn>

                <v-text-field v-model="props.items[index].email" maxlength="100" dense single-line
                              :rules="[field_rules.max100, field_rules.email]">

                </v-text-field>-->
                        <!--<v-text-field v-model="props.items[index].email" maxlength="100" dense single-line readonly>

                        </v-text-field>
                    </v-row>
                </v-form>-->

                        <v-virtual-scroll height="300" :items="props.items" class="ma-3"
                                          item-height="50" bench="3">
                            <template v-slot:default="{ item }">

                                <v-text-field v-model="item.email" maxlength="100" dense single-line readonly class="pa-2">

                                </v-text-field>
                            </template>

                        </v-virtual-scroll>

                    </template>


                </v-data-iterator>

                <v-divider></v-divider>

                <v-row dense align="center" justify="center" class="mt-2">

                    <v-btn color="black" outlined elevation="4"
                           class="ma-2" @click="triggerUpload"
                           fab>
                        <v-icon dark>
                            $import
                        </v-icon>
                    </v-btn>

                    <v-file-input accept=".csv" prepend-icon="$import" label="File input" ref='fileInput' v-model="fileUpload" style="display:none;">

                    </v-file-input>

                    <v-btn color="red darken-4" outlined elevation="4" :disabled="!valid_form"
                           class="ma-2" @click="removeRole"
                           fab>
                        <v-icon dark>
                            $databaseRemove
                        </v-icon>
                    </v-btn>

                    <v-btn color="green darken-4" outlined elevation="4" :disabled="!valid_form"
                           class="ma-2" @click="addRole"
                           fab>
                        <v-icon dark>
                            $databaseAdd
                        </v-icon>
                    </v-btn>


                </v-row>

            </v-card>


            <v-overlay absolute :value="loading_init">
                <v-progress-circular indeterminate :size="80" :width="7"
                                     color="primary"></v-progress-circular>
            </v-overlay>

            <!--role edit report dialog-->
            <v-dialog v-model="dialog" :fullscreen="isMobile"  width="800px" scrollable eager persistent>

                <v-card tile class="filter-card" style="height: 70vh; overflow-y: scroll;  ">

                    <v-toolbar flat dark color="secondary" max-height="64px">

                        <v-btn icon dark @click="closeDialog">
                            <v-icon>$close</v-icon>
                        </v-btn>

                        <v-toolbar-title style="padding-left:0 !important">Role Edit Results</v-toolbar-title>

                    </v-toolbar>

                    <div class="ma-5 report-container">

                        <div class="mb-3">
                            <strong>App:</strong> {{dialogOptions.appname}} <br />
                            <strong> Role:</strong> {{dialogOptions.rolename}} <br />
                            <strong>Operation:</strong> {{dialogOptions.op}} Role
                        </div>

                        <v-divider></v-divider>

                        <div class="my-3" v-if="dialogOptions.op == `Add` && edit_report.succesfulRoleOPCount > 0">
                            <div>
                                <v-icon color="green" left class="mb-1" large>$successOutline</v-icon>
                                <strong>{{edit_report.succesfulRoleOPCount}}</strong> RPD Users successfully had the role added!
                            </div>
                            <v-divider></v-divider>
                        </div>

                        <div class="my-3" v-else-if="dialogOptions.op == `Remove` && edit_report.succesfulRoleOPCount > 0">
                            <div>
                                <v-icon color="green" left class="mb-1" large>$successOutline</v-icon>
                                <strong>{{edit_report.succesfulRoleOPCount}}</strong> RPD Users successfully had the role removed!
                            </div>
                            <v-divider></v-divider>
                        </div>

                        <div class="my-3" v-if="dialogOptions.op == `Add` && edit_report.existingRoleOPCount > 0">
                            <div>
                                <v-btn icon color="orange" left class="mr-2" @click="downloadexistingUsersCSV">
                                    <v-icon large>$info</v-icon>
                                </v-btn>
                                <strong>{{edit_report.existingRoleOPCount}}</strong> RPD Users already had the role.
                                <v-virtual-scroll height="200" :items="edit_report.existingUsers" class="mx-3 mb-3"
                                                  item-height="50" bench="3">
                                    <template v-slot:default="{ item }">
                                        <v-text-field v-model="item.email" maxlength="100" dense single-line readonly class="pa-2">
                                        </v-text-field>
                                    </template>

                                </v-virtual-scroll>
                            </div>
                            <v-divider></v-divider>
                        </div>

                        <div class="my-3" v-else-if="dialogOptions.op == `Remove` && edit_report.existingRoleOPCount > 0">
                            <div>
                     
                                <v-btn icon color="orange" left class="mr-2" @click="downloadexistingUsersCSV">
                                    <v-icon large>$info</v-icon>
                                </v-btn>
                                <strong>{{edit_report.existingRoleOPCount}}</strong> RPD Users did not have the role.
                                <v-virtual-scroll height="200" :items="edit_report.existingUsers" class="mx-3 mb-3"
                                                  item-height="50" bench="3">
                                    <template v-slot:default="{ item }">
                                        <v-text-field v-model="item.email" maxlength="100" dense single-line readonly class="pa-2">
                                        </v-text-field>
                                    </template>

                                </v-virtual-scroll>
                            </div>
                            <v-divider></v-divider>
                        </div>

                        <div class="my-3" v-if="edit_report.errorRoleOPCount > 0">
                            <div>
                                <v-btn icon color="red" left class="mr-2" @click="downloaderrorUsersCSV">
                                    <v-icon large>$info</v-icon>
                                </v-btn>
                                <strong>{{edit_report.errorRoleOPCount}}</strong> emails were not found in the database as RPD Users.
                                <v-virtual-scroll height="200" :items="edit_report.errorUsers" class="mx-3 mb-3"
                                                  item-height="50" bench="3">
                                    <template v-slot:default="{ item }">
                                        <v-text-field v-model="item.email" maxlength="100" dense single-line readonly class="pa-2">
                                        </v-text-field>
                                    </template>

                                </v-virtual-scroll>
                            </div>
                            <v-divider></v-divider>
                        </div>

                        

                    </div>

                    <v-card-actions style="align-self: center;">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeDialog" text class="pa-2 ma-2 text-center">Close</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                </v-card>

            </v-dialog>


        </div>
    </Transition>
</template>


<style lang="scss" scoped>
    .users_container {
        padding: 10px;
        height: 300px;
        overflow-y: scroll;
        resize: vertical;
    }

    </style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import Papa from "papaparse";
    export default {
        name: "editroles",
        mixins: [bus_common],
        props: ["results_search"],
        data() {
            return {
                apps: [],
                roles: [],
                app_select: 0,
                role_select: 0,
                loading_init: false,
                results_form_valid: false,
                search_emails: "",
                no_results_text: "No users found",
                loading_text: "No users found",
                abc: "name,andy, john, bob, fred, greg",
                papa_result: null,
                field_rules: {
                    required: value => !!value || 'This field is required',
                    max100: v => v.length <= 100 || 'Max 100 characters',
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    },

                },
                fileUpload: null,
                dialog: false,
                isMobile: this.$vuetify.breakpoint.mobile,
                edit_report: {
                    succesfulRoleOPCount: 0,
                    existingRoleOPCount: 0,
                    errorRoleOPCount: 0,
                    existingUsers: [],
                    errorUsers: [],
                },
                dialogOptions: {
                    op: "Add",
                    appname: "NA",
                    rolename: "NA",
                },
            };
        },
        methods: {
            async checkAdminRole() {
                //Double check the user has the correct roles
                let callback = () => { window.location.replace(this.loginURL); }

                if (this.$store.state.user.roles.indexOf('rl-admin') == -1) {

                    this.$swal({
                        title: "Invalid Role!",
                        text: "You do not have the needed role for this app, you will be redirected to the login page.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#283593',
                        timer: 4000,
                        didClose: callback
                    });
                }
            },
            async init() {

                this.loading_init = true;
                await this.checkAdminRole();

                var form = {
                    token: this.$store.state.user.token
                };

                await this.$axios.post('api/roles/batchInit', form)
                    .then(response => {
                        this.apps = response.data;

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },
            async queryUsers() {

                this.loading_init = true;

                var form = {
                    token: this.$store.state.user.token,
                    applicationId: this.app_select,
                    rolesId: this.role_select
                };

                await this.$axios.post('api/roles/batchSearch', form)
                    .then(response => {
                        this.results_search = response.data;

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },
            downloadCSV() {

                if (this.papa_result != null) {
                    var csv = Papa.unparse(this.papa_result);
                    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    var csvURL = window.URL.createObjectURL(csvData);
                    var testLink = document.createElement('a');
                    testLink.href = csvURL;
                    var dt = this.$moment(Date.now()).tz('America/Los_Angeles').format("YYYYMMDD-HHmm");
                    testLink.download = 'RolesQuery_' + this.role_select.toString() + "_" + dt + '.csv';
                    testLink.setAttribute('test', 'test.csv');
                    testLink.click();
                }


            },
            click_downloadCSV() {
                var config = {
                    quotes: false, //or array of booleans
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ",",
                    header: true,
                    newline: "\r\n",
                    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                    columns: null //or array of strings
                }
                this.papa_result = JSON.parse(JSON.stringify(this.results_search))
                this.downloadCSV();
            },
            triggerUpload() {
                var buttonSrc = this.$refs.fileInput.$el.getElementsByTagName('button')[0];
                buttonSrc.click();

            },
            processUpload() {
                if (this.fileUpload.type != "text/csv") {
                    let msg = "This file upload only accepts .csv files. Please try again. "
                    this.swall_it("Error!", msg, "error", null, null);
                    this.fileUpload = null;
                }
                else {
                    this.parseFileInput();
                }
            },
            parseFileInput() {
                this.$emit('clearResultSearch');
                this.loading_init = true;

                Papa.parse(this.fileUpload, {
                    worker: true,
                    header: true,
                    step: (row) => {

                        this.$emit('addResultSearch', row.data);
                    },
                    complete: () => {
                        console.log("All done!");
                        this.loading_init = false;
                        this.fileUpload = null;
                    }
                });
            },
            addRole() {
                let callback = () => {
                    this.modifyRoles("Add");
                }

                const appindex = this.apps.findIndex(item => item.applicationId === this.app_select);
                const roleindex = this.apps[appindex].roles.findIndex(item => item.rlsID === this.role_select);

                let appname = this.apps[appindex].appname;
                let rolename = this.apps[appindex].roles[roleindex].roledisplay;
                this.dialogOptions.appname = appname;
                this.dialogOptions.rolename = rolename;
                appname = "<b>" + appname + "</b>"
                rolename = "<b>" + rolename + "</b>"
                let usercount = "<b>" + this.results_search.length.toString() + "</b>"

                let msg = "Are you sure you would like to <b>add</b> the " + rolename + " role for the " + appname + " app to these " + usercount + " users?"
                this.swall_diag("Add " + rolename + " Role?", msg, "question", callback, "Yes", "No", null)
            },
            removeRole() {
                let callback = () => {
                    this.modifyRoles("Remove");
                }

                const appindex = this.apps.findIndex(item => item.applicationId === this.app_select);
                const roleindex = this.apps[appindex].roles.findIndex(item => item.rlsID === this.role_select);

                let appname = this.apps[appindex].appname;
                let rolename = this.apps[appindex].roles[roleindex].roledisplay;
                this.dialogOptions.appname = appname;
                this.dialogOptions.rolename = rolename;
                appname = "<b>" + appname + "</b>"
                rolename = "<b>" + rolename + "</b>"
                let usercount = "<b>" + this.results_search.length.toString() + "</b>"

                let msg = "Are you sure you would like to <b>remove</b> the " + rolename + " role for the " + appname + " app from these " + usercount + " users?"
                this.swall_diag("Remove " + rolename + " Role?", msg, "question", callback, "Yes", "No", null)
            },
            async modifyRoles(OP) {

                if (OP == "Add") {

                    this.loading_init = true;

                    var form = {
                        token: this.$store.state.user.token,
                        email: this.$store.state.user.email,
                        applicationId: this.app_select,
                        rolesId: this.role_select,
                        batchusers: JSON.parse(JSON.stringify(this.results_search))
                    };

                    await this.$axios.post('api/roles/addRole', form)
                        .then(response => {
                            this.edit_report = response.data;
                            this.dialogOptions.op = OP;
                            this.dialog = true;

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });

                    this.loading_init = false;

                }

                else if (OP == "Remove") {
                    this.loading_init = true;

                    var form = {
                        token: this.$store.state.user.token,
                        email: this.$store.state.user.email,
                        applicationId: this.app_select,
                        rolesId: this.role_select,
                        batchusers: JSON.parse(JSON.stringify(this.results_search))
                    };

                    await this.$axios.post('api/roles/removeRole', form)
                        .then(response => {
                            this.edit_report = response.data;
                            this.dialogOptions.op = OP;
                            this.dialog = true;

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });

                    this.loading_init = false;
                }

                else {

                }

            },
            closeDialog() {
                this.dialog = false;
            },
            downloadexistingUsersCSV() {

                var csv = Papa.unparse(JSON.parse(JSON.stringify(this.edit_report.existingUsers)));
                var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var csvURL = window.URL.createObjectURL(csvData);
                var testLink = document.createElement('a');
                testLink.href = csvURL;
                var dt = this.$moment(Date.now()).tz('America/Los_Angeles').format("YYYYMMDD-HHmm");
                testLink.download = 'RolesReport_' + dt + '.csv';
                testLink.setAttribute('test', 'test.csv');
                testLink.click();


            },
            downloaderrorUsersCSV() {

                var csv = Papa.unparse(JSON.parse(JSON.stringify(this.edit_report.errorUsers)));
                var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var csvURL = window.URL.createObjectURL(csvData);
                var testLink = document.createElement('a');
                testLink.href = csvURL;
                var dt = this.$moment(Date.now()).tz('America/Los_Angeles').format("YYYYMMDD-HHmm");
                testLink.download = 'RolesReport_' + dt + '.csv';
                testLink.setAttribute('test', 'test.csv');
                testLink.click();


            },

        },
        computed: {
            valid_form: function () {
                if (this.app_select != 0 && this.role_select != 0 && this.results_search.length != 0)
                    return true;
                else
                    return false;
            }
        },
        watch: {
            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "" && this.loading_init == false) {

                    this.init();
                }
            },
            app_select() {
                this.role_select = 0;
                const index = this.apps.findIndex(item => item.applicationId === this.app_select);
                if (index != -1) {
                    this.roles = this.apps[index].roles;
                }
            },
            fileUpload() {
                if (this.fileUpload != null) {
                    this.processUpload();
                }
            },



        },
        mounted() {

            if (this.$store.state.user.token != "" && this.loading_init == false) {

                this.init();
            }


        },
    };
</script>
