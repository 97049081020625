<template>
    <Transition name="fade-transition">
        <div id="rolesdash">



            <v-tabs v-model="tab" centered background-color="secondary" dark height="60px"
                    class="secondary white--text text-h6">
                <v-tab>
                    Defaults
                </v-tab>
                <v-tab>
                    Query
                </v-tab>
                <v-tab>
                    Edit
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <defaultapps></defaultapps>
                </v-tab-item>
                <v-tab-item>
                    <queryroles @exportUsers="exportUsers"></queryroles>
                </v-tab-item>
                <v-tab-item>
                    <editroles :results_search="results_search" @addResultSearch="addResultSearch" @clearResultSearch="clearResultSearch">

                    </editroles>
                </v-tab-item>
            </v-tabs-items>


        </div>
    </Transition>
</template>


<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import defaultapps from "./defaultapps";
    import queryroles from "./queryroles";
    import editroles from "./editroles";
    export default {
        name: "rolesdash",
        mixins: [bus_common],
        components: { defaultapps, queryroles, editroles },
        data() {
            return {
                tab: 1,
                results_search: []

            };
        },
        methods: {

            exportUsers(results_search) {

                this.results_search = results_search;
                this.tab = 2;

            },

            addResultSearch(user) {

                this.results_search.push(user);
            },

            clearResultSearch() {

                this.results_search = [];
            }
        },
        computed: {



        },
        watch: {

            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "" && this.loading_init == false) {
                    this.init();
                }
            },



        },
        mounted() {





        },
    };
</script>
