<template>
    <Transition name="fade-transition">
        <div id="batchroles">

            <v-card class="pa-5 ">

                <v-row dense>
                    <v-col cols="12" lg="6">
                        <v-select :items="apps" item-text="appname" item-value="applicationId" v-model="app_select" outlined label="App"></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select :items="roles" item-text="roledisplay" item-value="rlsID" v-model="role_select" outlined label="Role"></v-select>
                    </v-col>
                </v-row>

                <v-data-iterator :items="results_search" :search="search_emails" :no-results-text="no_results_text" :loading-text="loading_text" :no-data-text="no_results_text"
                                 hide-default-footer disable-pagination>

                    <template v-slot:header>
                        <v-toolbar color="secondary lighten-1" dark dense elevation="4" class="mb-2">
                            <v-toolbar-title>Users ({{results_search.length}})</v-toolbar-title>
                        </v-toolbar>
                    </template>

                    <template v-slot:no-data>

                        <h4 class="ma-3">No users found</h4>

                    </template>

                    <template v-slot:no-results>

                        <h4 class="ma-3">No users found</h4>

                    </template>

                    <template v-slot:default="props">

                        <!--<v-form ref="results_form" v-model="results_form_valid" class="users_container">-->
                        <!--<v-row no-gutters dense v-for="(item,index) in props.items" :key="index">-->
                        <!--<v-btn small icon color="red darken-1" class="mr-1 mt-1">
                            <v-icon>
                                $delete
                            </v-icon>
                        </v-btn>

                        <v-text-field v-model="props.items[index].email" maxlength="100" dense single-line
                                      :rules="[field_rules.max100, field_rules.email]">

                        </v-text-field>-->
                        <!--<v-text-field v-model="props.items[index].email" maxlength="100" dense single-line readonly>

                            </v-text-field>
                        </v-row>-->
                        <!--<v-virtual-scroll height="300" :items="props.items"
                                              item-height="50">
                                <template v-slot:default="{ item }">
                            
                                    <v-text-field v-model="item.email" maxlength="100" dense single-line readonly>

                                    </v-text-field>
                                </template>

                            </v-virtual-scroll>
                        </v-form>-->

                        <v-virtual-scroll height="300" :items="props.items" class="ma-3"
                                          item-height="50" bench="3">
                            <template v-slot:default="{ item }">

                                <v-text-field v-model="item.email" maxlength="100" dense single-line readonly class="pa-2">

                                </v-text-field>
                            </template>

                        </v-virtual-scroll>
                    </template>


                </v-data-iterator>

                <v-divider></v-divider>

                <v-row dense align="center" justify="center" class="mt-2">

                    <v-btn color="black" outlined elevation="4" :disabled="results_search.length == 0"
                           class="ma-2" @click="click_downloadCSV"
                           fab>
                        <v-icon dark>
                            $download
                        </v-icon>
                    </v-btn>

                    <v-btn color="blue darken-4" outlined elevation="4" @click="queryUsers"
                           class="ma-2" :disabled="!valid_search"
                           fab>
                        <v-icon dark>
                            $magnify
                        </v-icon>
                    </v-btn>

                    <v-btn color="green darken-4" outlined elevation="4" :disabled="results_search.length == 0"
                           class="ma-2" @click="exportUsers"
                           fab>
                        <v-icon dark>
                            $export
                        </v-icon>
                    </v-btn>
                </v-row>

            </v-card>

            <v-overlay absolute :value="loading_init">
                <v-progress-circular indeterminate :size="80" :width="7"
                                     color="primary"></v-progress-circular>
            </v-overlay>

        </div>
    </Transition>
</template>


<style lang="scss" scoped>
    .users_container {
        padding: 10px;
        height: 300px;
        overflow-y: scroll;
        resize: vertical;
    }
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import Papa from "papaparse";
    export default {
        name: "queryroles",
        mixins: [bus_common],
        data() {
            return {
                apps: [],
                roles: [],
                app_select: 0,
                role_select: 0,
                loading_init: false,
                results_form_valid: false,
                results_search: [],
                search_emails: "",
                no_results_text: "No users found",
                loading_text: "No users found",
                abc: "name,andy, john, bob, fred, greg",
                papa_result: null,
                field_rules: {
                    required: value => !!value || 'This field is required',
                    max100: v => v.length <= 100 || 'Max 100 characters',
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    },

                },
            };
        },
        methods: {
            async checkAdminRole() {
                //Double check the user has the correct roles
                let callback = () => { window.location.replace(this.loginURL); }

                if (this.$store.state.user.roles.indexOf('rl-admin') == -1) {

                    this.$swal({
                        title: "Invalid Role!",
                        text: "You do not have the needed role for this app, you will be redirected to the login page.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#283593',
                        timer: 4000,
                        didClose: callback
                    });
                }
            },
            async init() {

                this.loading_init = true;
                await this.checkAdminRole();

                var form = {
                    token: this.$store.state.user.token
                };

                await this.$axios.post('api/roles/batchInit', form)
                    .then(response => {
                        this.apps = response.data;

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },
            async queryUsers() {

                this.loading_init = true;

                var form = {
                    token: this.$store.state.user.token,
                    applicationId: this.app_select,
                    rolesId: this.role_select
                };

                await this.$axios.post('api/roles/batchSearch', form)
                    .then(response => {
                        this.results_search = response.data;

                    }).catch(error => {
                        if (error.response) {
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });

                this.loading_init = false;
            },
            parseInput() {
                var config = {
                    delimiter: "",	// auto-detect
                    newline: "",	// auto-detect
                    quoteChar: '"',
                    escapeChar: '"',
                    header: false,
                    transformHeader: undefined,
                    dynamicTyping: false,
                    preview: 0,
                    encoding: "",
                    worker: false,
                    comments: false,
                    step: undefined,
                    complete: undefined,
                    error: undefined,
                    download: false,
                    downloadRequestHeaders: undefined,
                    downloadRequestBody: undefined,
                    skipEmptyLines: false,
                    chunk: undefined,
                    chunkSize: undefined,
                    fastMode: undefined,
                    beforeFirstChunk: undefined,
                    withCredentials: undefined,
                    transform: undefined,
                    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
                };

                this.papa_result = Papa.parse(this.abc, config);


            },
            downloadCSV() {

                if (this.papa_result != null) {
                    var csv = Papa.unparse(this.papa_result);
                    var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    var csvURL = window.URL.createObjectURL(csvData);
                    var testLink = document.createElement('a');
                    testLink.href = csvURL;
                    var dt = this.$moment(Date.now()).tz('America/Los_Angeles').format("YYYYMMDD-HHmm");
                    testLink.download = 'RolesQuery_' + this.role_select.toString() + "_" + dt + '.csv';
                    testLink.setAttribute('test', 'test.csv');
                    testLink.click();
                }


            },
            click_downloadCSV() {
                var config = {
                    quotes: false, //or array of booleans
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ",",
                    header: true,
                    newline: "\r\n",
                    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                    columns: null //or array of strings
                }
                this.papa_result = JSON.parse(JSON.stringify(this.results_search))
                this.downloadCSV();
            },
            exportUsers() {
                let callback = () => {
                    let copySearch = JSON.parse(JSON.stringify(this.results_search))
                    this.results_search = [];
                    this.$emit('exportUsers', copySearch);
                }
                this.swall_diag("Transfer Users?", "Are you sure you would like to transfer this list of users to edit their roles?", "question", callback, "Yes", "No", null)

            }



        },
        computed: {

            valid_search: function () {
                if (this.app_select != 0 && this.role_select != 0)
                    return true;
                else
                    return false;
            }


        },
        watch: {
            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "" && this.loading_init == false) {

                    this.init();
                }
            },

            app_select: function () {
                this.role_select = 0;
                const index = this.apps.findIndex(item => item.applicationId === this.app_select);
                if (index != -1) {
                    this.roles = this.apps[index].roles;
                }
            },

        },
        mounted() {

            if (this.$store.state.user.token != "" && this.loading_init == false) {

                this.init();
            }

            this.parseInput();
        },
    };
</script>
